import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          JUNTE-SE À NOSSA NEWSLETTER
        </p>
        <p className="footer-subscription-text">Esteja sempre atualizado</p>
        <div className="input-areas">
          <form>
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Seu Email"
            />
            <Button buttonStyle="btn--outline">Subscrever</Button>
          </form>
        </div>
      </section>
      <div class="footer-links">
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>Sobre Nós</h2>
            <Link to="/about">Quem Somos</Link>
            <Link to="/">Marcas</Link>
            <Link to="/">Produtos</Link>
            <Link to="/">Investidores</Link>
            <Link to="/">Termos e Serviços</Link>
          </div>
          <div class="footer-link-items">
            <h2>Contate-nos</h2>
            <Link to="/">Contatos</Link>
            <Link to="/">Suporte</Link>
            <Link to="/">Patrocinadores</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>Ofertas</h2>
            <Link to="/">Submeter Oferta</Link>
            <Link to="/">Embaixadores</Link>
            <Link to="/">Agência</Link>
          </div>
          <div class="footer-link-items">
            <h2>Redes Sociais</h2>
            <Link to="https://www.facebook.com/profile.php?id=100088441566811">Facebook</Link>
            <Link to="https://www.instagram.com/p/CnCiG0IojDg/?igshid=MDJmNzVkMjY=">Instagram</Link>
            <Link to="https://www.linkedin.com/company/88056620">LinkedIn</Link>
          </div>
        </div>
      </div>
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="footer-logo">
            <Link to="/" className="social-logo">
              VDP
            </Link>
          </div>
          <small class="website-rights">VDP © 2023</small>
          <div class="social-icons">
            <Link
              class="social-icon-link facebook"
              to="https://www.facebook.com/profile.php?id=100088441566811"
              target="_blank"
              aria-label="Facebook"
            >
              <i class="fab fa-facebook-f" />
            </Link>
            <Link
              class="social-icon-link instagram"
              to="https://www.instagram.com/p/CnCiG0IojDg/?igshid=MDJmNzVkMjY="
              target="_blank"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram" />
            </Link>
            <Link
              class="social-icon-link twitter"
              to="https://www.linkedin.com/company/88056620"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
