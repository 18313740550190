import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h1>VENHA CONHECER!</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/image-1.jpg"
              text="Venha descobrir tudo sobre nós."
              label="Sobre Nós"
              path="/about"
            />
            <CardItem
              src="images/image-4.jpg"
              text="Venha descobrir os nossos produtos de qualidade."
              label="Loja"
              path="/store"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/image-2.jpg"
              text="Entre em contato connosco."
              label="Contatos"
              path="/contacts"
            />
            <CardItem
              src="images/image-3.jpg"
              text="Todas as notícias atualizadas."
              label="Notícias"
              path="/news"
            />
            <CardItem
              src="images/image-5.jpg"
              text="Outras informações importantes."
              label="Informações"
              path="/info"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
