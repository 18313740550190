import React from "react";
import "../../App.css";
import { Link } from "react-router-dom";

export default function Contacts() {
    return (
        <div className="contacts">
            <h1>CONTATOS</h1>

            <Link to="mainto:valedopinheiro@outlook.com">valedopinheiro@outlook.com</Link>
            <Link to="valedopinheiro.pt@gmail.com">valedopinheiro.pt@gmail.com</Link>

        </div>
    );
}
