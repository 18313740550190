import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Store from "./components/pages/Store";
import Contacts from "./components/pages/Contacts";
import News from "./components/pages/News";
import Info from "./components/pages/Info";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/store" element={<Store />}></Route>
          <Route path="/contacts" element={<Contacts />}></Route>
          <Route path="/news" element={<News />}></Route>
          <Route path="/info" element={<Info />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
