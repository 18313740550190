import React, { Fragment, useState } from "react";
import "./About.css";

const About = () => {
  const [toggleTab, setToggleTab] = useState(1);
  const toggleState = (index) => {
    setToggleTab(index);
  };
  return (
    <Fragment>
      <section className="about">
        <div className="row">
          <div className="column">
            <div className="about-img"></div>
          </div>

          <div className="column">
            <div className="tabs">
              <div
                className={
                  toggleTab === 1 ? "single-tab active-tab" : "single-tab"
                }
                onClick={() => toggleState(1)}
              >
                <h2>About</h2>
              </div>

              <div
                className={
                  toggleTab === 2 ? "single-tab active-tab" : "single-tab"
                }
                onClick={() => toggleState(2)}
              >
                <h2>Blog</h2>
              </div>
            </div>

            <div className="tab-content">
              {/* About Content */}

              <div
                className={
                  toggleTab === 1 ? "content active-content" : "content"
                }
              >
                <h2>A nossa história</h2>
                <p>
                  “Ter um destino é não caber no berço onde o corpo nasceu, é transpor as fronteiras uma a uma e morrer sem nenhuma!” Miguel Torga
                </p>
              </div>

              {/* Skills Content */}

              <div
                className={
                  toggleTab === 2 ? "content active-content" : "content"
                }
              >
                <h2>Blog</h2>
                <p>
                  Disponivel Brevemente
                </p>

                <div className="skills-row">
                  <div className="skills-column">
                    <div className="progress-wrap">
                      <h3>Energia</h3>
                      <div className="progress">
                        <div className="progress-bar">
                          <span>80%</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="skills-column">
                    <div className="progress-wrap">
                      <h3>Natureza</h3>
                      <div className="progress">
                        <div className="progress-bar Designer">
                          <span>90%</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="skills-column">
                    <div className="progress-wrap">
                      <h3>Paixâo</h3>
                      <div className="progress">
                        <div className="progress-bar Javascript">
                          <span>85%</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="skills-column">
                    <div className="progress-wrap">
                      <h3>Amor</h3>
                      <div className="progress">
                        <div className="progress-bar PhotoShop">
                          <span>88%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default About;
